import React from 'react';
import { Button } from 'antd';
import instantDev from '../../assets/icons/instantDev.svg';
import './style.less';

const HeroSection = () => {
  const scrollToFeatures = () => {
    const featuresSection = document.querySelector('.features-section');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="hero-content">
      <div className="hero-text fade-in-up">
        <h1>GeekCode Cloud</h1>
        <p className="hero-subtitle">一键启动云端开发环境，让编码更自由</p>
        <div className="hero-actions">
          <Button 
            type="primary" 
            size="large" 
            className="try-button"
            href="https://console.geekcode.cloud/login/"
            target="_blank"
          >
            登录
          </Button>
          <Button 
            size="large" 
            className="learn-more"
            onClick={scrollToFeatures}
          >
            了解更多
          </Button>
        </div>
      </div>

      <div className="hero-image fade-in-up delay-2">
        <div className="image-container">
          <img src={instantDev} alt="GeekCode 预览" />
          <div className="glow-effect" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection; 