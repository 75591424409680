import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import * as icons from '../../assets/icons'
import './index.less'
import { ScrollToControl,geekcodeCloud } from '../../common'

const textArr = [
  '远程开发',
  '联调应用',
  '分享环境',
  '测试程序',
  '审核代码',
]

let timer,
  countNum = 0,
  len = textArr.length

export default function ConetentHeader({locale}) {
  const [count, setCount] = useState(0)
  const [local,setLocal] = useState(locale)
  const [prevNum, setPrevNum] = useState(len - 1)


  const scrollBottom = () => {
    ScrollToControl('item6')
  }
  useEffect(() => {
    timer = setInterval(() => {
      countNum++
      if (countNum === len) {
        countNum = 0
      }
      setCount(countNum)
      if (countNum === 0) {
        setPrevNum(len - 1)
      } else {
        setPrevNum(countNum - 1)
      }
    }, 2000)

    return () => {
      clearInterval(timer)
    }
  }, [count])

  useEffect(()=>{
    setLocal(locale)
  },[locale])

  return (
    <div className="conetent-header-bar">
      <div className="text">
        <FormattedMessage id="headDesc" defaultMessage="GeekCode.Cloud 云开发环境">
          {(text) => (<>
          {
            (local ==='en' && document.body.clientWidth > 980) ? 
            <><p style={{letterSpacing:'-2px'}}>
            {text}&nbsp;
            <span className="test">
              <FormattedMessage
                id="headDesc1"
                defaultMessage="让软件工程师们一起"
              />
            </span>
          </p>
          <p style={{ marginLeft: '0px',gridAutoFlow:'row',gridTemplateColumns:'none' }}>
            <span className="active-text-list" style={{ display: 'grid', height: '71px',textAlign:'center' }}>
              {textArr.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={
                      count === index
                        ? 'active-text-item active-text-show active-text-en'
                        : prevNum === index
                          ? 'active-text-item active-text-prev active-text-en'
                          : 'active-text-item active-text-en'
                    }
                  >
                    <FormattedMessage
                      id={'headItem' + index}
                      defaultMessage={item}
                    />
                  </span>
                )
              })}
            </span>
          </p></>
            :<><p>{text}</p>
          <p>
            <span className="test">
              <FormattedMessage
                id="headDesc1"
                defaultMessage="让软件工程师们一起"
              />
            </span>
            <span className="active-text-list">
              {textArr.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={
                      count === index
                        ? 'active-text-item active-text-show'
                        : prevNum === index
                          ? 'active-text-item active-text-prev'
                          : 'active-text-item'
                    }
                  >
                    <FormattedMessage
                      id={'headItem' + index}
                      defaultMessage={item}
                    />
                  </span>
                )
              })}
            </span>
          </p></>
          }
          </>)}
        </FormattedMessage>

      </div>
      <div className="btn-box">
        <Button type="primary" onClick={scrollBottom}>
          <FormattedMessage id="contactUsBtn" defaultMessage="联系我们" />
        </Button>
        <Button href={geekcodeCloud} target="_blank"><FormattedMessage id="reserveBtn" defaultMessage="开始使用"/></Button>
      </div>
    </div>
  )
}
