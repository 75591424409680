import React from 'react';
import { Card, Button } from 'antd';
import './style.less';

const plans = [
  {
    type: 'free',
    title: '免费版',
    price: '¥0',
    desc: '适合个人开发者',
    features: [
      '基础开发环境',
      '代码自动补全',
      '基础版本控制',
      '社区支持'
    ]
  },
  {
    type: 'pro',
    title: '专业版',
    price: '¥99/月',
    desc: '适合专业开发者',
    features: [
      '高级开发环境',
      'AI 智能补全',
      '团队协作功能',
      '优先技术支持'
    ]
  },
  {
    type: 'team',
    title: '团队版',
    price: '¥299/月',
    desc: '适合开发团队',
    features: [
      '企业级环境',
      '团队管理功能',
      '高级安全特性',
      '专属技术支持'
    ]
  }
];

const PricingSection = () => {
  return (
    <div className="pricing-plans">
      {plans.map((plan) => (
        <Card key={plan.type} className={`pricing-card ${plan.type}`}>
          <h3>{plan.title}</h3>
          <div className="price">{plan.price}</div>
          <p className="desc">{plan.desc}</p>
          <ul className="features">
            {plan.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <Button 
            type={plan.type === 'pro' ? 'primary' : 'default'} 
            size="large"
            href="https://console.geekcode.cloud/login/"
            target="_blank"
          >
            立即开始
          </Button>
        </Card>
      ))}
    </div>
  );
};

export default PricingSection;
