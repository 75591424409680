import React from 'react'
import AppRoutes from '../../routes/index-mobile'
import Header from '../Header/Mobile'
import Footer from '../Footer/Mobile'

const MobLayout = ({setLocale, locale}) => {
  return (
    <div className="container">
      <Header changeLag={setLocale} />
      <div className="content">
        <AppRoutes locale={locale} />
      </div>
      <Footer />
    </div>
  )
}

export default MobLayout