import React from 'react'
import { Divider } from 'antd'
import * as icons from '../../assets/icons'
import { FormattedMessage } from "react-intl";
import './price.less'

const PriceCard = ({ item, pId }) => {
  return (
    <div className='price-card-box'>
      <div className='price-card'>
        <div className='card-top'>
          <div className='title'>
            <div className='left'>
              <span className='price'>
              <FormattedMessage id={`pricingCard${pId}Num`} defaultMessage={item.price} />
                </span>
              <span className='unit'>
                {
                  pId !==2 ? <FormattedMessage id="pricingMoUnit" defaultMessage={item.unit} />
                  :<FormattedMessage id="pricingUserMoUnit" defaultMessage={item.unit} />
                }
                </span>
              {item.suffix && <span className='suffix'>
              <FormattedMessage id="pricingSuffix" defaultMessage={item.suffix} />
              </span>}
            </div>
            <div className='right'>
              {item.introduce && <span className='introduce'>
                <FormattedMessage id={`pricingCard${pId}Title`} defaultMessage={item.introduce} />
              </span>}
              {item.introduceColor && <span className='introduceColor'>
                <FormattedMessage id={`pricingCard${pId}Title`} defaultMessage={item.introduceColor} />
              </span>}
            </div>
          </div>
          <p className='describe'>
            <FormattedMessage id={`pricingCard${pId}SubTitle`} defaultMessage={item.describe} />
          </p>
        </div>
        <Divider />
        <div className='card-content'>
          {
            item.content.map((i, index) => {
              return <p key={`priceContent+${index}`}><img src={icons.selectIcon} alt='selectIcon' />
                <FormattedMessage id={`pricingCard${pId}List${index}`} defaultMessage={i} />
              </p>
            })
          }
        </div>
      </div>
    </div>

  )
}
export default PriceCard
