/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import './pc.less'
import * as icons from '../../assets/icons'

const Header = ({ changeLag }) => {
  const [acKey, setAcKey] = useState()
  const locale = sessionStorage.getItem('locale') || window.navigator.language.toLowerCase().slice(0, 2) || 'zh';
  const defText = locale === 'zh' ? 'English' : '中文';
  const [localeLag, setLocaleLag] = useState(defText)
  const [showBg, setShowBg] = useState(false)


  useEffect(() => {
    const handleScroll = () => {
      if (Number(window.scrollY) > 10) {
        setShowBg(true)
      } else {
        setShowBg(false)
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };


  useEffect(() => {
    const pathNameArr = window.location.pathname.split('/')
    if (pathNameArr.length >= 2) {
      setAcKey(`/${pathNameArr[1]}`)
    }
  }, [])

  return (
    <div className='header' style={showBg ? { background: '#2B3262' } : {}}>
      <div className="header-container">
        <div className='header-left'>
          <div className='logo'>
            <img src={icons.logo1} alt="Logo" />
          </div>
        </div>
        <div className='header-right'>
          <div className='header-nav'>
            <a className='nav-link' onClick={() => scrollToSection('#features')}>
              产品特性
            </a>
            <a className='nav-link' onClick={() => scrollToSection('#tech')}>
              技术优势
            </a>
            <a className='nav-link' onClick={() => scrollToSection('#use-cases')}>
              应用场景
            </a>
            <a className='nav-link' onClick={() => scrollToSection('#pricing')}>
              定价方案
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header




