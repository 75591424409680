import React from 'react'
import AppRoutes from '../../routes/index-pc'
import Header from '../Header/Pc'
import Footer from '../Footer/Pc'

const PCLayout = ({ setLocale, locale }) => {
  return (
    <div className="container">
      <Header changeLag={setLocale} />
      <div className="content">
        <AppRoutes locale={locale} />
      </div>
      <Footer />
    </div>
  )
}

export default PCLayout;