import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { PcHome } from '../pages'

const AppRoutes = ({locale}) => (
  <Routes>
    <Route path="/" element={<PcHome locale={locale} />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

export default AppRoutes
