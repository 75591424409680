import {FormattedMessage } from 'react-intl';
import './footer.less'
const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-container grid'>
        <div className='menu-list'>
          <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href="/">关于我们</a>
          <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href="/">联系我们</a>
          <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href="/">帮助中心</a>
        </div>
        <p className='copy-right'>
          Copyright &copy; 深圳阿拉丁五人科技有限公司 | 
          <a style={{ color: 'inherit', textDecoration: 'none' }} target='_blank' rel="noreferrer" href="https://beian.miit.gov.cn/">粤ICP备2022119416号</a>
        </p>
      </div>
    </div>)
}
export default Footer