import React, { useState, useEffect } from 'react'
import './carouse.less'
import List from './List'
import { FormattedMessage } from 'react-intl'
import * as icons from '../../assets/icons'
import {gitHubGear} from '../../common'

const dotsList = [
  { text: 'git 代码仓库一键生成开发环境', img: icons.instantDev, imgEN: icons.instantDev, link: gitHubGear},
  { text: '编译加速，写代码更流畅', img: icons.compilation, imgEN: icons.compilationEN },
  { text: 'Dev.URL 让团队工作更高效', img: icons.devUrl, imgEN: icons.devUrlEN},
  { text: '支持多个应用', img: icons.moreApplications, imgEN: icons.moreApplications },
  { text: '即开即用', img: icons.readyUse, imgEN: icons.readyUseEN },
]

export const PcCarousel = ({locale}) => {
  const [checkIdx, setCheckIdx] = useState(null)
  const [isAuto, setIsAuto] = useState(true)

  useEffect(() => {
    let timer;
    if (isAuto) {
      timer = setInterval(() => {
        autoPlay();
      }, 3000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isAuto, checkIdx]);

  const dotsClick = (idx) => {
    setCheckIdx(idx)
    setIsAuto(false)
  }

  const autoPlay = () => {
    let nextIdx = checkIdx === null ? 0 : (checkIdx + 1) % dotsList.length;
    setCheckIdx(nextIdx);
  }

  return (
    <div className='carouse-box'>
      <div className='carouse-content'>
        <div className='carouse-left'>
          {dotsList.map((item, idx) => (
            <div
              key={idx}
              className={`dots-item ${checkIdx === idx ? 'active' : ''}`}
              onClick={() => dotsClick(idx)}
            >
              <span className='dots' />
              <span className='text'>
                <FormattedMessage id={`carouselText${idx + 1}`} defaultMessage={item.text} />
              </span>
            </div>
          ))}
        </div>
        <div className='carouse-right'>
          {dotsList.map((item, idx) => (
            <div
              key={idx}
              className={`img-box ${checkIdx === idx ? 'active' : ''}`}
            >
              {item.link ? (
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img src={locale === 'en' ? item.imgEN : item.img} alt={item.text} />
                </a>
              ) : (
                <img src={locale === 'en' ? item.imgEN : item.img} alt={item.text} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const MoCarousel = ({locale}) => {
  const [checkIdx, setCheckIdx] = useState(null)
  const [isAuto, setIsAuto] = useState(true)

  useEffect(() => {
    let timer;
    if (isAuto) {
      timer = setInterval(() => {
        autoPlay();
      }, 3000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isAuto, checkIdx]);

  const dotsClick = (idx) => {
    setCheckIdx(idx)
    setIsAuto(false)
  }

  const autoPlay = () => {
    let nextIdx = checkIdx === null ? 0 : (checkIdx + 1) % dotsList.length;
    setCheckIdx(nextIdx);
  }

  return (
    <div className='carouse-box'>
      <div className='carouse-content'>
        {dotsList.map((item, idx) => (
          <div
            key={idx}
            className={`carouse-item ${checkIdx === idx ? 'active' : ''}`}
            onClick={() => dotsClick(idx)}
          >
            <div className='img-box'>
              {item.link ? (
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img src={locale === 'en' ? item.imgEN : item.img} alt={item.text} />
                </a>
              ) : (
                <img src={locale === 'en' ? item.imgEN : item.img} alt={item.text} />
              )}
            </div>
            <div className='text'>
              <FormattedMessage id={`carouselText${idx + 1}`} defaultMessage={item.text} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
