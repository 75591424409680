import React, { useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import './emailForm.less'
// 包含普通邮箱和vip邮箱模式
const regs = /^([a-zA-Z\d])(\w|-|\.)+@([a-zA-Z\d]|([vip]+\.[a-zA-Z\d]{2,4}))+\.[a-zA-Z]{2,4}$/;
const EmailForm = ({ formLayout, intl }) => {
  const [form] = Form.useForm();
  const [isOk, setIsOk] = useState(false);
  const onFinish = (values) => {
    setIsOk(true);
    // 调用接口提交
    fetch("/api/email/add?email="+values.email).then(r =>{
      const {status} = r;
      if(status === 200){
        message.success(intl.formatMessage({id: 'submitSuccess'}))
      }else{
        console.error(r)
        setIsOk(false);
      }
    })
  };


  return ( 
    <Form form={form} className="email-form" name="email_push" layout={formLayout} onFinish={onFinish} autoComplete="off">
      <Form.Item name="email" rules={[{required:true,type:'string',whitespace:true},{validator:(_, value)=>{
              if (value) {
                if(regs.test(value)){
                  return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({id: 'emailRules'})));
              }
              return Promise.reject();
            }}]}>
        {/* <Input placeholder="Please enter your email" /> */}
        <Input placeholder={intl.formatMessage({id: 'inputPlaceholder'})} />
      </Form.Item>
      <Form.Item >
        <Button disabled={isOk} type="primary" htmlType="submit" className="emial-button"><FormattedMessage id="freeTrialBtn" defaultMessage="立即体验"/></Button>
      </Form.Item>
    </Form>)
}
export default injectIntl(EmailForm)