import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App';
import {geekcodeGit} from './common'

const locHash = window.location.hash
if(locHash && locHash.includes('github.com')){
  window.location.replace(`${geekcodeGit}${locHash}`);
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
