// eslint-disable-next-line import/no-anonymous-default-export
export default {
  geekLamp: "Interview",
  geekCode: "Cloud IDE",
  pricing: "Pricing",
  discussions: "Discussions",
  docs: "Wiki",
  menuItem3: "Get Started",
  headDesc: "GeekCode.Cloud",
  headDesc1: "Empowers developers to",
  freeTrialBtn: "Free Trial",
  contactUsBtn: "Contact Us",
  reserveBtn: "Get Started",
  headItem0: "Work from anywhere",
  headItem1: "Integration test",
  headItem2: "Share dev environments",
  headItem3: "Test application",
  headItem4: "Review code",
  item3Title: "GeekCode was developed on GeekCode",
  item3SubTitle: "Deploy in private or via API, connecting upstream and downstream developers.",
  item3ItemTitle0: "Dev URL",
  item3ItemInfo0: "No test machine is required, and the front and back ends are seamlessly connected.",
  item3ItemTitle1: "Instant development",
  item3ItemInfo1: "Git code repository generates a development environment with one click.",
  item3ItemTitle2: "Pre-compiled templates",
  item3ItemInfo2: " Utilize pre-compiled development environments with or without customization, to deploy the development environment in minimum time.",
  item3ItemTitle3: "Boost compile",
  item3ItemInfo3: "Boost compile Rust/C++ code.",
  item4Title: "Why choose",
  carouse0: "Git code repository generates a development environment with one click",
  carouse1: "Faster compilation and smoother code writing",
  carouse2: "Dev.url makes the team work more efficiently",
  carouse3: "Support for multiple applications",
  carouse4: "Ready to use",
  // carouse4: "Private deployment installation",
  // carouse5: "Centralized coding environment management",
  item5Title: "Scenarios",
  item5SubTitle: "Removing the complexity of runtime environment during development and tests, GeekCode.Cloud IDE will be ideal for",
  item5ListTitle0: "Projects that rely on multiple environments",
  item5ListInfo0: "When multiple dependent runtime environments (such as x86 and ARM architecture) are involved, you do not have to switch between terms to compile your binaries in different environments.",
  item5ListTitle1: "Seamless communication between front-end and back-end engineers",
  item5ListInfo1: "Test server is no longer needed in Dev URL development, and remote collaborative development is seamlessly connected.",
  item5ListTitle2: "Managing public cloud tokens at test time",
  item5ListInfo2: "Cloud IDE manages public cloud tokens, so that you can access your resources more conveniently.",
  item5ListTitle3: "Runtime A/B tests",
  item5ListInfo3: "With Cloud IDE, you can implement runtime A/B tests in the cloud to evaluate your local environment.",
  item5ListTitle4: "Proof of Concept (PoC) of open-source software",
  item5ListInfo4: "With our built-in cloud-based environments, you can create a development environment for open-source software to get closer to your developers.",
  item5ListTitle5: "Project-based assessment in developer interviews",
  item5ListInfo5: "During technical interviews, you can see how a candidate works on and know what they think of a project, so as to assess their competence quickly.",
  item6WechatText:"Wechat scan code to join",
  item6FlybookText:"Lark scan code to join",
  item6UserText:"GeekCode user group",
  item6Title: "Collaborative Coding, Anytime, Anywhere",
  item6SubTitle: "Enter your e-mail, and try it on the web or download the Community Edition for FREE!",
  inputPlaceholder: "Please enter your email",
  emailRules: "E-mail format is incorrect",
  submitSuccess: "Success!",
  footerTab1: "Privacy & security",
  footerTab2: "Terms of use",
  footerTab3: "Legal information",
  copyRight: " 2025 深圳阿拉丁五人科技有限公司",
  pricingTitle: "Simple and Affordable Pricing",
  pricingFooterText: "Go Try ～",
  pricingMoUnit: "/mo",
  pricingUserMoUnit: "/user/mo",
  pricingSuffix: "rise",
  pricingCard0Num: "$0",
  pricingCard0Title: "Source / Edu.",
  pricingCard0SubTitle: "coding on the cloud",
  pricingCard0List0: "1 workspace",
  pricingCard0List1: "Free storage (2GB)",
  pricingCard0List2: "1 vCPU",
  pricingCard0List3: "2GB RAM",
  pricingCard0List4: "Image management",
  pricingCard0List5: "Workspace allowing collaboration and sharing",
  pricingCard1Num: "$21",
  pricingCard1Title: "Professionals",
  pricingCard1SubTitle: "Improve development efficiency",
  pricingCard1List0: "Unlimited workspaces",
  pricingCard1List1: "Faster cross-regional network connection",
  pricingCard1List2: "5GB+ ROM (per workspace)",
  pricingCard1List3: "4GB+ RAM (per workspace)",
  pricingCard1List4: "2core+ vCPUs (per workspace)",
  pricingCard1List5: "160+ hours uptime per month",
  pricingCard1List6: "Host with better performance",
  pricingCard2Num: "$105",
  pricingCard2Title: "Business",
  pricingCard2SubTitle: "Safer, stronger, and allow private deployment and private integration",
  pricingCard2List0: "Unlimited workspaces",
  pricingCard2List1: "Team member management",
  pricingCard2List2: "Development process auditing",
  pricingCard2List3: "Multi-cloud cross-cluster deployment",
  pricingCard2List4: "Private deployment",
  pricingCard2List5: "Faster cross-regional network connection",

}