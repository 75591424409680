import { useState, useEffect } from "react";
import { Menu, Button, Dropdown } from "antd";
import { FormattedMessage } from "react-intl";
import GitHubButton from 'react-github-btn'
import * as icons from "../../assets/icons";
import "./mobile.less";
import { GithubURL, geekLamp, BBSURL, geekcodeCloud,Twitter,Medium } from "../../common";
const Header = ({ changeLag }) => {
  const [acKey, setAcKey] = useState();
  const locale =
    sessionStorage.getItem("locale") ||
    window.navigator.language.toLowerCase().slice(0, 2) ||
    "zh";
  const defText = locale === "zh" ? "English" : "中文";
  const [localeLag, setLocaleLag] = useState(defText);
  const [isMenuVisible, setIsMenuVisible] = useState(false); //下拉菜单是否可见

  const changeLocale = () => {
    let locale = localeLag === "English" ? "en" : "zh";
    changeLag(locale);
    sessionStorage.setItem("locale", locale);
    setLocaleLag(locale === "en" ? "中文" : "English");
    setIsMenuVisible(false);
  };


  const menu = (
    <Menu onClick={() => setIsMenuVisible(!isMenuVisible)}>
      {/* <Menu.Item key="GithubURL" >
        <a
          className="doc-btn btn-style"
          target="_blank"
          rel="noreferrer"
          href={GithubURL}
        >
          <span className="github" />
          Github
        </a>
      </Menu.Item>
      <Menu.Item key="Discussions">
        <a
          className="doc-btn btn-style"
          target="_blank"
          rel="noreferrer"
          href={BBSURL}
        >
          <span className="discussions" />
          <FormattedMessage id="docs" defaultMessage="文档" />
        </a>
      </Menu.Item> */}
      {/* <Menu.Item key="GeekCode" >
        <a
          className="doc-btn btn-style"
          target="_blank"
          rel="noreferrer"
          href={geekcodeCloud}
        >
          <span className="cloudIDE" />
          <FormattedMessage id="geekCode" defaultMessage="云IDE" />
        </a>
      </Menu.Item>
      <Menu.Item key="geekLamp" >
        <a
          className="doc-btn btn-style"
          target="_blank"
          rel="noreferrer"
          href={geekLamp}
        >
          <span className="interview" />
          <FormattedMessage id="geekLamp" defaultMessage="代码面试" />
        </a>
      </Menu.Item> */}
      <Menu.Item key="pricePage" >
        <a className="doc-btn btn-style" href="#item7" target='_self' rel="noreferrer">
          <span className="pricing" />
          <FormattedMessage id="pricing" defaultMessage="价格" />
        </a>
      </Menu.Item>
      {/* <Menu.Item key="twitterPage" >
        <a className="doc-btn btn-style" target="_blank" rel="noreferrer" href={Twitter}>
          <span className="twitter" />
          Twitter
        </a>
      </Menu.Item>
      <Menu.Item key="mediumPage" >
        <a className="doc-btn btn-style" href={Medium} target='_blank' rel="noreferrer">
          <span className="medium" />
          Medium
        </a>
      </Menu.Item> */}
      <Menu.Item key="localeBtn">
        <Button
          className="changeLocaleBtn"
          type="text"
          size="small"
          onClick={changeLocale}
        >
          {localeLag}
        </Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const pathNameArr = window.location.pathname.split("/");
    if (pathNameArr.length >= 2) {
      setAcKey(`/${pathNameArr[1]}`);
    }
  }, [acKey]);

  return (
    <div className="header">
      <div className="mobile-header-container grid grid-col">
        <div className="logo">
          <img src={icons.logo1} alt="Logo" />
        </div>
        <div className="header-right">
        <GitHubButton href="https://github.com/geeklamp/geekcode.cloud" data-color-scheme="no-preference: dark_high_contrast; light: dark_high_contrast; dark: dark_high_contrast;" data-size="large" data-show-count="true" aria-label="Star geeklamp/geekcode.cloud on GitHub">Star</GitHubButton>
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            onVisibleChange={(visible) => setIsMenuVisible(visible)}
          >
            <div className="menu-btn" onClick={(e) => e.preventDefault()}>
              <img alt="menu" src={isMenuVisible ? icons.menuOn : icons.menu} />
            </div>
          </Dropdown>
        </div>
        {/* <a className='login-btn' target='_blank' rel="noreferrer" href="/">login in</a> */}
      </div>
    </div>
  );
};
export default Header;
