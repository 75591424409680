import React from 'react';
import { Card } from 'antd';
import { planIcon0, planIcon1, planIcon2, planIcon3 } from '../../assets/icons';
import './style.less';

const features = [
  {
    icon: planIcon0,
    title: '实时协作',
    desc: '团队成员实时同步，告别代码冲突'
  },
  {
    icon: planIcon1,
    title: 'AI 智能助手',
    desc: '代码智能补全，开发效率翻倍'
  },
  {
    icon: planIcon2,
    title: '一键部署',
    desc: '从开发到上线，一气呵成'
  },
  {
    icon: planIcon3,
    title: '版本管理',
    desc: '内置 Git 工作流，代码管理更轻松'
  }
];

const FeatureGrid = () => {
  return (
    <div className="feature-grid">
      {features.map((feature, index) => (
        <div 
          key={index} 
          className="feature-item fade-in-up"
          style={{ animationDelay: `${index * 0.1}s` }}
        >
          <Card className="feature-card">
            <div className="feature-icon">
              <img src={feature.icon} alt={feature.title} />
            </div>
            <h3>{feature.title}</h3>
            <p>{feature.desc}</p>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default FeatureGrid; 