import React from "react";
import { Helmet } from 'react-helmet';
import "./pc.less";
import * as icons from "../../assets/icons";

// 组件导入
import HeroSection from "../../components/HeroSection";
import FeatureGrid from "../../components/FeatureGrid";
import TechShowcase from "../../components/TechShowcase";
import UseCases from "../../components/UseCases";
import VideoDemo from "../../components/VideoDemo";
import PricingSection from "../../components/PricingSection";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>GeekCode IDE - 轻量级云端开发环境 | 让小团队开发更简单</title>
        <meta name="description" content="GeekCode IDE 基于 VSCode 打造的云端开发环境，无需复杂配置，支持浏览器直接访问，让小团队和创业公司快速搭建专业的开发环境，享受和大厂一样的开发体验。" />
        <meta name="keywords" content="云端IDE,VSCode,在线开发环境,小团队开发,创业公司,远程协作,代码编辑器,开发环境" />
        <link rel="canonical" href="https://ide.geekcode.cloud" />
      </Helmet>

      <main className="home-container" itemScope itemType="https://schema.org/WebApplication">
        <div className="background-effects" aria-hidden="true">
          <div className="gradient-orb top-right" />
          <div className="gradient-orb bottom-left" />
          <div className="grid-overlay" />
          <img 
            src={icons.topLineBg} 
            className="floating-graphic"
            alt=""
            aria-hidden="true"
          />
        </div>

        <div className="content-wrapper">
          <section className="hero-section" role="banner">
            <HeroSection />
          </section>

          <section className="video-section" aria-label="产品演示视频">
            <VideoDemo />
          </section>

          <section className="features-section" id="features" aria-labelledby="features-title">
            <div className="section-header">
              <h2 id="features-title">专业的云端开发环境</h2>
              <p>基于 VSCode，无需配置维护，让小团队专注于创造价值</p>
            </div>
            <FeatureGrid />
          </section>

          <section className="tech-section" id="tech" aria-labelledby="tech-title">
            <TechShowcase />
          </section>

          <section className="use-cases-section" id="use-cases" aria-labelledby="use-cases-title">
            <div className="section-header">
              <h2 id="use-cases-title">为小团队打造</h2>
              <p>无论是远程办公、临时协作，还是创业起步，都能快速上手使用</p>
            </div>
            <UseCases />
          </section>

          <section className="pricing-section" id="pricing" aria-labelledby="pricing-title">
            <div className="section-header">
              <h2 id="pricing-title">灵活的付费方案</h2>
              <p>按需使用，随团队成长</p>
            </div>
            <PricingSection />
            <p className="pricing-footer">
              即刻体验云端开发的便捷
            </p>
          </section>
        </div>
      </main>
    </>
  );
};

export default Home;
