import React from 'react';
import { videoPoster_zh } from '../../assets/icons';
import geekcode_video from '../../assets/video/geekcode_cloud_zh.mp4';
import './style.less';

const VideoDemo = () => {
  return (
    <div className="video-demo">
      <div className="video-description">
        <h3>云端开发，如此简单</h3>
        <p>一分钟了解 GeekCode 如何革新您的开发方式</p>
      </div>
      <div className="video-container">
        <video
          controls
          poster={videoPoster_zh}
          className="demo-video"
        >
          <source src={geekcode_video} type="video/mp4" />
          您的浏览器不支持视频播放
        </video>
        <div className="video-overlay" />
      </div>
    </div>
  );
};

export default VideoDemo; 