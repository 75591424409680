import React from 'react';
import { Card } from 'antd';
import { prodIcon0, prodIcon1, prodIcon2, prodIcon3 } from '../../assets/icons';
import './style.less';

const useCases = [
  {
    icon: prodIcon0,
    title: '创业团队',
    desc: '一键启动开发环境，省去运维成本，专注产品开发'
  },
  {
    icon: prodIcon1,
    title: '远程协作',
    desc: '浏览器直接访问，无需配置本地环境，让远程开发更简单'
  },
  {
    icon: prodIcon2,
    title: '外包团队',
    desc: '快速搭建统一环境，新人分钟级上手，提升项目交付效率'
  },
  {
    icon: prodIcon3,
    title: '独立开发者',
    desc: '告别繁琐环境配置，随时随地开发，享受专业级工具支持'
  }
];

const UseCases = () => {
  return (
    <div className="use-cases">
      {useCases.map((useCase, index) => (
        <div 
          key={index} 
          className="use-case-item fade-in-up"
          style={{ animationDelay: `${index * 0.1}s` }}
        >
          <Card className="use-case-card">
            <div className="use-case-icon">
              <img src={useCase.icon} alt={useCase.title} />
            </div>
            <h3>{useCase.title}</h3>
            <p>{useCase.desc}</p>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default UseCases; 