import React from 'react';
import { Carousel } from 'antd';
import { compilation, readyUse, instantDev } from '../../assets/icons';
import './style.less';

const TechShowcase = () => {
  const items = [
    {
      title: '智能代码补全',
      desc: '基于 AI 的智能代码补全，提高开发效率',
      features: [
        '实时智能提示，减少重复编码',
        '支持多种主流编程语言',
        '基于项目上下文的智能建议'
      ],
      image: compilation
    },
    {
      title: '实时协作',
      desc: '多人实时编辑，团队开发更高效',
      features: [
        '实时同步代码变更',
        '内置代码评审工具',
        '团队协作无缝对接'
      ],
      image: readyUse
    },
    {
      title: '一键部署',
      desc: '快速部署到云端，随时随地访问',
      features: [
        '支持多种部署环境',
        '自动化构建和部署',
        '完整的部署日志记录'
      ],
      image: instantDev
    }
  ];

  return (
    <div className="tech-showcase">
      <Carousel autoplay className="showcase-carousel">
        {items.map((item, index) => (
          <div key={index} className="showcase-item">
            <div className="showcase-content">
              <div className="showcase-text">
                <h3>{item.title}</h3>
                <p className="main-desc">{item.desc}</p>
                <ul className="feature-list">
                  {item.features.map((feature, idx) => (
                    <li key={idx}>
                      <span className="bullet">•</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="showcase-image">
                <img src={item.image} alt={item.title} />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TechShowcase; 